function convertCamelToNormal(camelCasedString) {
    if (typeof camelCasedString !== "string") return "";
  
    return camelCasedString
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
  }
  const StringUtilities = {
    convertCamelToNormal,
  };
  
  export default StringUtilities;
  