import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  getwishlists,
  addwishlists,
  globalLoderAction,
  // getsingleproducts,
  addcarts,
} from "../Redux/Action";
// import Swal from "sweetalert2";
import { message } from "antd";
const Token = localStorage.getItem("AuthTok");

// import wishlistimage from "../wishlist.svg";

function Whishlist({ setActiveTab }) {
  let navigate = useNavigate();

  const [load, setLoad] = useState(true);
  // const [render, setRender] = useState(true);
  const dispatch = useDispatch();
  const wish = useSelector(({ getwishlists }) => getwishlists.payload);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getwishlists())])
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const handleClick = (id) => {
    cartId.variantId = id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[change];

    if (Token) {
      dispatch(addcarts(cartId)).then((res) => {
        message.success({
          content: res?.data?.data,
          style: {
            marginTop: "10vh",
          },
        });
        setActiveTab(2);
      });
    } else {
      navigate("/login", {
        state: { from: window.location.pathname, reloadAfterLogin: true },
      });
    }
  };
  useEffect(() => {
    // Check if reload is needed after login
    if (location.state?.reloadAfterLogin) {
      window.location.reload();
    }
  }, [location]);
  return (
    <>
      {" "}
      <section class="cart-wraps-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <form>
                <div class="cart-wraps">
                  <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        {Array.isArray(wish?.data) &&
                        wish?.data?.length !== 0 ? (
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>

                            <th scope="col"> Add to Cart</th>
                            <th scope="col"> Action</th>
                          </tr>
                        ) : (
                          false
                        )}
                      </thead>

                      {Array.isArray(wish?.data) && wish?.data?.length !== 0 ? (
                        wish?.data?.map((w, i) => (
                          <tbody>
                            <tr>
                              <td class="product-thumbnail proo">
                                <Link to={"/ProductDetails/" + w.id}>
                                  <img
                                    src={
                                      process.env.REACT_APP_URL + w.productImage
                                    }
                                    alt="item"
                                    style={{ objectFit: "cover" }}
                                  />
                                </Link>
                              </td>

                              <td class="product-name">
                                <Link
                                  to={"/ProductDetails/" + w.id}
                                  style={{
                                    color: "black",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {w.productName}
                                </Link>
                              </td>

                              <td class="product-price">
                                <span className="unit-amount">
                                  {typeof w.discountPrice == "number" ? (
                                    <>₹{w.discountPrice}</>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Product Currently Not Available
                                    </span>
                                  )}
                                </span>
                              </td>

                              <td class="product-subtotal">
                                <a
                                  onClick={() => handleClick(w.id)}
                                  class="default-btn border-radius-5"
                                >
                                  {" "}
                                  Add to Cart
                                </a>
                              </td>
                              <td class="product-subtotal">
                                {" "}
                                <a
                                  onClick={() => {
                                    setLoad(true);
                                    dispatch(addwishlists({ productId: w.id }))
                                      .then((res) => {
                                        message.success({
                                          content: res?.data?.data,
                                          style: {
                                            marginTop: "10vh",
                                          },
                                        });
                                        dispatch(getwishlists());
                                        setActiveTab(2);
                                        setLoad(false);
                                      })
                                      .catch((error) => {
                                        message.error({
                                          content: error.message,
                                          style: {
                                            marginTop: "10vh",
                                          },
                                        });
                                        setLoad(false);
                                      });
                                  }}
                                  className="remove"
                                >
                                  <i className="bx bx-trash"></i>
                                </a>
                              </td>
                            </tr>{" "}
                          </tbody>
                        ))
                      ) : (
                        <center>
                          {/* <img
											   src={wishlistimage}
											   className="mt-5 mb-5"
											   style={{ width: "25%" }}
											 /> */}
                          <h3 className="mb-5 pb-3"> No Wishlist Products</h3>
                          <a
                            href="/shop"
                            className="default-btn border-radius-5"
                          >
                            Shop Now
                          </a>
                        </center>
                      )}
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Whishlist;
