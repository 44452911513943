import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetpasswords, globalLoderAction } from "../Redux/Action";
import { message } from "antd";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(1000, 0);
    setError(localStorage.getItem("Error"));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();

    dispatch(resetpasswords({ email: email }))
      .then((res) => {
        setLoad(true);
        message.warning({
          content:res.data.data ,
          style: {
            marginTop: "10vh",
          },
        });
        navigate("/VerifyPassword/" + email);
      })
      .catch((err) => {
        const errMsg = err.response?.data?.message;
        console.log(err);

        if (
          errMsg === "Oops...! Limit Exceeded, Kindly Try Again after 60mins."
        ) {
          navigate("/ForgotPassword");
          message.warning({
            content: errMsg,
            style: {
              marginTop: "10vh",
            },
          });
        } else if (err.response?.data?.error?.message === "User Not Found") {
          navigate("/ForgotPassword");
          message.warning({
            content: "User Not Found",
            style: {
              marginTop: "10vh",
            },
          });
        } else {
          setError(errMsg || "An error occurred");
        }
      });
  };

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Forgot Password</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Forgot Password</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6">
              <div class="user-all-form">
                <div class="contact-form">
                  <h3 class="user-title"> Forget Password</h3>

                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            class="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            data-error="Enter Email Address*"
                            placeholder="Enter Email Address*"
                          />
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          class="default-btn border-radius-5"
                        >
                          Reset Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForgotPassword;
