// import React from 'react'
import axios from "axios";

export function setHeaderToken(token) {
  if (token) {
    axios.defaults.headers.common["authtoken"] =
      localStorage.getItem("AuthTok");
  } else {
    delete axios.defaults.headers.common["  "];
  }
}

export function axiosInstance(method, path, data, params, token, header) {
  var method = method;
  var data = data;
  axios.defaults.headers.common["authtoken"] = localStorage.getItem("AuthTok");
  return axios({
    url: path,
    method: method.toLowerCase(), // default
    baseURL: process.env.REACT_APP_URL,
    headers: { "Content-Type": "application/json", ...(header ? header : {}) },
    params: params,
    data: data,
    timeout: 0,
  });
}
