import { Modal, ModalBody,ModalHeader } from "reactstrap";

export function CustomModal({isOpen,toggle, bannerImg, ...args}) {
  return (
    <div>
      <Modal centered size={"lg"} isOpen={isOpen} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <img
            style={{ display: "block", width: "100%" }}
            src={bannerImg}
            alt="welcome banner img"
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
