import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import paymet_Error from "../assets/image/paymet_Error.jpg";
import paymet_Success from "../assets/image/paymet_Success.jpg";
import { payNows } from "../Redux/Action";
import StringUtilities from "./StringUtilities";

function PaymentStatus() {
  const dispatch = useDispatch();

  const [load, setLoad] = useState(true);
  const [paymentStatusMessage, setPaymentStatusMessage] = useState("");
  const [orderInfo, setOrderInfo] = useState(null);

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("order_id");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (orderId) {
      dispatch(payNows({ orderId }))
        .then((res) => {
          const { message, orderInfo } = res.data.data;
        

          setPaymentStatusMessage(message);
          setOrderInfo(orderInfo);
          window.fbq("track", "Purchase", {
            currency: "INR",
            value: orderInfo?.totalAmount || 
            "500",
          });
        })
        .catch((err) => {
          setPaymentStatusMessage(err.response.data.error.message);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    return () => {};
  }, []);

  return (
    <div className="section section-margin">
      <div className="container">
        <div className="row justify-content-center mb-n4">
          <img
            className="col-6"
            style={{
              minWidth: "400px",
              opacity: "0.9",
            }}
            src={
              paymentStatusMessage === "Order Placed Successfully"
                ? paymet_Success
                : paymet_Error
            }
            alt="paymet_Success_illustartion"
          />

          <p className="text-center col-12 success h6">
            {paymentStatusMessage}
          </p>
          {orderInfo && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                flexDirection: "column",
                margin: "3px auto",
                padding: "10px",
                fontSize: "16px",
              }}
            >
              {Object.entries(orderInfo).map(([label, value]) => {
                return (
                  <span>
                    {StringUtilities.convertCamelToNormal(label + "")} :
                    <span>
                      {" "}
                      {StringUtilities.convertCamelToNormal(value + "")}
                    </span>
                  </span>
                );
              })}
            </div>
          )}
          <Link
            style={{
              maxWidth: "fit-content",
              textAlign: "center",
              margin: "10px",
            }}
            className="default-btn order-btn mb-5"
            to="/order"
          >
            View Order
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentStatus;
