import React, { useState, useEffect } from "react";

function Error404() {
  return (
    <>
      <div class="inner-banner inner-banner-bg9">
        <div class="container">
          <div class="inner-title">
            <h3>404 Error Page</h3>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>404 Error Page</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="error-area ptb-100">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="error-content">
              <h1>
                4 <span>0</span> 4
              </h1>
              <h3>Oops! Page Not Found</h3>
              <p>
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
              <a href="index.html" class="default-btn">
                Return To Home Page
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Error404;
