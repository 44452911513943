import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "./Home.css";
import {
  getcategoryproducts,
  addwishlists,
  globalLoderAction,
  getwishlists,
} from "../Redux/Action";
import { BsFillSuitHeartFill } from "react-icons/bs";
// import { FaHeart, FaRegHeart } from "react-icons/fa";
// import Swal from "sweetalert2";
import { message } from "antd";
// import products from "../noproductsfound.svg";

function CategoryShop() {
  const [load, setLoad] = useState(false);
  const { categoryId } = useParams();
  const location = useLocation();
  const categoryName = location?.state && location?.state?.categoryName;
  console.log(categoryName);
  const dispatch = useDispatch();
  const catproduct = useSelector(
    ({ getcategoryproducts }) => getcategoryproducts.payload
  );
  const wish = useSelector(({ getwishlists }) => getwishlists.payload);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getwishlists())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));

    if (categoryId) {
      Promise.allSettled([dispatch(getcategoryproducts({ categoryId }))])
        .then((res) => setLoad(false))
        .catch(() => {
          console.log("Waiting For Network");
        })
        .finally(() => {
          dispatch(globalLoderAction("hideLoader"));
        });
    }
  }, [categoryId,dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Category Products</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Categories
                </a>
              </li>
              <li style={{ color: "#fff", textTransform: "capitalize" }}>
                {categoryName}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section class="shop-area pt-100 pb-100">
        <div class="container">
          <div class="woocommerce-topbar">
            <div class="row align-items-center">
              <div class="col-lg-8 col-md-7 col-sm-6">
                <div class="woocommerce-result-count">
                  {/* <p>Showing 1-8 of 14 results</p> */}
                </div>
              </div>
              <div class="col-lg-4 col-md-5 col-sm-6">
                <div class="woocommerce-topbar-ordering"></div>
              </div>
            </div>
          </div>
          <div class="row">
            {Array.isArray(catproduct) && catproduct.length !== 0 ? (
              catproduct.map((x) => (
                <div class="col-lg-3 col-md-6">
                  <div class="top-branded-card mb-30">
                    <div class="top-branded-img">
                      <Link
                        to={"/ProductDetails/" + x.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        <img
                          src={process.env.REACT_APP_URL + x.productImage}
                          alt="Top Branded"
                          style={{ height: "350px", objectFit: "cover" }}
                        />
                      </Link>
                      <ul class="top-branded-action">
                        <li>
                          <a
                            onClick={() => {
                              setLoad(true);
                              dispatch(addwishlists({ productId: x.id })).then(
                                (res) => {
                                  message.success({
                                    content: res.data.data,
                                    style: {
                                      marginTop: "10vh",
                                    },
                                  });
                                  setLoad(false);
                                }
                              );
                            }}
                          >
                            {Array.isArray(wish?.data) &&
                            wish?.data?.length !== 0 ? (
                              wish?.data?.some((item) => item.id === x.id) ? (
                                <>
                                  <i className="flaticon-like"></i>
                                </>
                              ) : (
                                <BsFillSuitHeartFill
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "8px",
                                  }}
                                />
                              )
                            ) : (
                              <i className="flaticon-like"></i>
                            )}
                          </a>
                        </li>

                        {/* <li>
                          <Link
                            to={"/ProductDetails/" + x.id}
                            style={{ textTransform: "capitalize" }}
                            data-bs-toggle="modal"
                          >
                            <i class="flaticon-visibility"></i>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                    <div class="content">
                      <h5 class="title-text">
                        <Link
                          to={"/ProductDetails/" + x.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {x.productName}
                        </Link>
                      </h5>
                      <h6>₹{x.discountPrice}</h6>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <center className="pb-5 mb-5">
                {/* <img
                  alt=""
                  src={products}
                  className="mt-5 mb-5"
                  style={{ width: "25%" }}
                /> */}
                <h3 className="container">Product Coming Soon</h3>
                <a
                  href="/categories"
                  className="default-btn border-radius-5 mt-5"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                >
                  Back to Home
                </a>
              </center>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default CategoryShop;
