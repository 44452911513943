import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifypasswords, globalLoderAction } from "../Redux/Action";
import { message } from "antd";

function VerifyPassword() {
  const { id } = useParams();
  const dispatch = useDispatch();
  // const [load, setLoad] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: id,
    code: "",
    password: "",
  });
  const [pass, setPass] = useState("");
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.password !== pass) {
      setError("Wrong Password");
      return; // Prevent further execution if the password doesn't match
    }

    dispatch(verifypasswords(data))
      .then((res) => {
        if (res.data.data.token) {
          message.success({
            content: "Password Changed Successfully",
            style: {
              marginTop: "10vh",
            },
          });
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        const errMsg = err.response?.data?.error?.message;
        if (errMsg === "Code Expired") {
          navigate("/ForgotPassword");
          message.warning({
            content: "Code Expired, Try Again!",
            style: {
              marginTop: "10vh",
            },
          });

          localStorage.setItem("Error", errMsg);
        } else if (
          err.response?.data?.message ===
          "Oops...! Limit Exceeded, Kindly Try Again after 60mins."
        ) {
          navigate("/ForgotPassword");
          message.warning({
            content: "Oops...! Limit Exceeded, Kindly Try Again after 60mins.",
            style: {
              marginTop: "10vh",
            },
          });
        } else {
          setError(errMsg || "An error occurred");
        }
      });
  };

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Verify Password</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Reset Password</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6">
              <div class="user-all-form">
                <div class="contact-form">
                  <h3 class="user-title"> Reset Password</h3>

                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-lg-12 ">
                        <div class="form-group">
                          <input
                            type="text"
                            onChange={handleChange}
                            name="code"
                            class="form-control"
                            placeholder="Verify Code"
                            required={true}
                          />
                        </div>
                        <div class="form-group">
                          {" "}
                          <input
                            type="text"
                            onChange={handleChange}
                            name="password"
                            class="form-control"
                            placeholder="Password"
                            required={true}
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            onChange={(e) => setPass(e.target.value)}
                            name="verifypassword"
                            placeholder="Verify Password"
                            required={true}
                          />
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          class="default-btn border-radius-5"
                        >
                          Reset Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyPassword;
