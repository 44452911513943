import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deletereviews, globalLoderAction } from "../Redux/Action";
// import Swal from "sweetalert2";
import { message } from "antd";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

function ReviewModal({ myreview, onClose }) {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [review, setReview] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const dispatch = useDispatch();
  console.log(myreview);

  const tid = myreview?.id || 0;
  const rate = myreview?.rating || 0;
  const revie = myreview?.review || "";

  useEffect(() => {
    setCurrentValue(rate);
    setReview(revie);
  }, [rate, revie]);

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const Editreview = () => {
    if (!review.trim()) {
      setValidationMessage("Review cannot be empty.");
      return;
    }
    setValidationMessage("");
    dispatch(
      deletereviews({
        action: "edit",
        id: tid,
        rating: currentValue,
        review: review,
      })
    ).then((res) => {
      message.success({
        content: res?.data?.data,
        style: {
          marginTop: "10vh",
        },
      });
      onClose();

      dispatch(globalLoderAction("hideLoader"));
    });
  };

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <div style={styles.container}>
      <h2>Ratings</h2>
      <div style={styles.stars}>
        {Array.from({ length: 5 }, (_, index) => (
          <FaStar
            key={index}
            size={20}
            onClick={() => handleClick(index + 1)}
            onMouseOver={() => handleMouseOver(index + 1)}
            onMouseLeave={handleMouseLeave}
            color={
              (hoverValue || currentValue) > index ? colors.orange : colors.grey
            }
            style={{
              marginRight: 10,
              cursor: "pointer",
            }}
          />
        ))}
      </div>
      <textarea
        placeholder="What's your experience?"
        onChange={(e) => setReview(e.target.value)}
        value={review}
        style={styles.textarea}
        required
      />
      {validationMessage && (
        <div style={styles.validationMessage}>{validationMessage}</div>
      )}
      <button style={styles.button} onClick={Editreview}>
        Submit
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 150,
    width: "100%", // Use 100% width for better responsiveness
    maxWidth: "400px",
  },
  validationMessage: {
    color: "red",
    marginBottom: 10,
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  },
};

export default ReviewModal;
