import React, { useState, useEffect } from "react";
import {
  // Link,
  useNavigate,
  useLocation,
} from "react-router-dom"; // Import useLocation to access navigation state
import {
  // authenticatelogin,
  globalLoderAction,
  googlelogin,
} from "../Redux/Action";
// import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { message } from "antd";

function Login() {
  const dispatch = useDispatch();
  const [passworderror, setPassworderror] = useState("");
  const [load, setLoad] = useState(false);
  // const [user, setUser] = useState({
  //   email: "",
  //   password: "",
  // });
  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const togglePasswordVisibility = () => {
  //   setPasswordVisible(!passwordVisible);
  // };
  // const handlechange = (e) => {
  //   setUser({ ...user, [e.target.name]: e.target.value });
  // };

  // Access navigation state (to know where to redirect after login)
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (user.password.length > 3) {
  //     dispatch(authenticatelogin(user))
  //       .then((res) => {
  //         setLoad(true);
  //         if (res.data.data === "Wrong Email/Password. Try Again!") {
  //           setPassworderror(res.data.data);
  //         } else {
  //           const redirectTo = location.state?.from || "/";
  //           navigate(redirectTo, {
  //             state: {
  //               fromLogin: true,
  //             },
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setPassworderror(err.response.data.error.message);
  //       });
  //   } else {
  //     setPassworderror("Password must be greater than 3 letters");
  //   }
  // };

  // Extract the access token from the Google login response
  const responseMessage = (response) => {
    console.log(response);
    const accessToken = response.credential;

    // Example check for token length
    console.log(accessToken);
    dispatch(googlelogin({ accessToken: accessToken }))
      .then((res) => {
        setLoad(true);
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          const redirectTo = location.state?.from || "/";
          navigate(redirectTo, {
            state: {
              fromLogin: true,
            },
          });
        }
      })
      .catch((err) => {
        setPassworderror(err);
        message.error({
          content: err,
          style: {
            marginTop: "10vh",
          },
        });
      });
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
      <div className="user-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="user-all-form">
                <div className="contact-form">
                  <h3 className="user-title">Login</h3>

                  <GoogleOAuthProvider clientId="664668234502-fm8hm218efco9iak3cvaulug6e06d400.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={responseMessage}
                      onError={errorMessage}
                    />
                  </GoogleOAuthProvider>
                  {/* <form id="contactForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            onChange={handlechange}
                            value={user.email}
                            required
                            className="form-control"
                            data-error="Email Address*"
                            placeholder="Email Address*"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group" style={{ display: "flex" }}>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            required
                            name="password"
                            value={user.password}
                            onChange={handlechange}
                            className="form-control"
                            placeholder="Enter Password*"
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderRadius: "none",
                              background: "none",
                              marginLeft: "-50px",
                              border: "none",
                              fontSize: "20px",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? (
                              <BsEyeSlash style={{ color: "#6b9246" }} />
                            ) : (
                              <BsEye style={{ color: "#6b9246" }} />
                            )}
                          </button>
                        </div>
                      </div>
                      <span style={{ color: "brown", marginLeft: "20px" }}>
                        {passworderror}
                      </span>
                      <div className="col-lg-12 form-condition">
                        <div className="agree-label">
                          <label htmlFor="chb1">
                            <a className="forget" href="/forgotpassword">
                              Forgot Password?
                            </a>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          className="default-btn border-radius-5"
                        >
                          Login Now
                        </button>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="user-all-form p-5">
                <div className="contact-form">
                  <h3 className="user-title">New Customer?</h3>
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-lg-12">
                        <h5>Create an Account</h5>
                      </div>

                      <div className="col-12">
                        <p>
                          Sign up for a free account at our store. Registration
                          is quick and easy. It allows you to be able to order
                          from our shop. To start shopping click register.
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <Link
                          to="/register"
                          state={{ from: location.state?.from || "/" }} // pass the state with 'from' URL
                        >
                          <button className="default-btn border-radius-5">
                            Register Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
