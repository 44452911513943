import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getcategories,globalLoderAction } from "../Redux/Action";

function Categories() {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const category = useSelector(({ getcategories }) => getcategories.payload);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getcategories())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
    
  }, [dispatch]);

  let navigate = useNavigate();
  function catshop(id, categoryName) {
    navigate(`/categoryshop/${id}`, { state: { categoryName } });
  }
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <>
     
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Categories</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Categories</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="blog-area pt-100 pb-70">
        <div class="container">
          <div class="row justify-content-center">
            {Array.isArray(category) && category.length !== 0 ? (
              category?.map((x) => (
                <div class="col-lg-3 col-md-6">
                  <div class="blog-item">
                    <a>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        onClick={() => catshop(x.id, x.categoryName)}
                        src={process.env.REACT_APP_URL + x.categoryImage}
                        alt={x.categoryName}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                        }}
                      />
                    </a>
                    <div class="content">
                      <h5
                        className="mt-3"
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        onClick={() => catshop(x.id, x.categoryName)}
                      >
                        {x.categoryName}
                      </h5>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h3 className="container">"No Category Found!"</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Categories;
