import React, { useState, useEffect } from "react";
import "./productdetail.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
// import { message } from "antd";
import { FaStar } from "react-icons/fa";
import { BsLink45Deg } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import DOMPurify from "dompurify";
import {
  getsingleproducts,
  addcarts,
  buynows,
  foryous,
  getwishlists,
  getreviews,
  productspecifications,
  globalLoderAction,
  addwishlists,
} from "../Redux/Action";
// import { BsFillSuitHeartFill } from "react-icons/bs";
// import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import user from ".././assets/image/user.png";
const Token = localStorage.getItem("AuthTok");

function Productdetail() {
  let navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();
  const location = useLocation();
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [change, setChange] = useState(0);
  const [changeVariant, setChangeVariant] = useState(0);
  const wishlist = useSelector(({ getwishlists }) => getwishlists.payload);

  useEffect(() => {
    const reloadFlag = localStorage.getItem("reloadFlag");
    const action = localStorage.getItem("action");

    console.log(action);

    const savedCartData = localStorage.getItem("cartData");
    if (location.state?.fromLogin) {
      if (savedCartData) {
        localStorage.setItem("reloadFlag", "true");
        window.location.reload();
      }

      navigate(location.pathname, { replace: true, state: {} });
    }

    if (reloadFlag === "true" && savedCartData) {
      const cartData = JSON.parse(savedCartData);
      if (action === "buyNow") {
        dispatch(addcarts(cartData))
          .then((res) => {
            setCart(res?.data?.data);
            localStorage.removeItem("reloadFlag");
            localStorage.removeItem("action");
            navigate("/cart");
          })
          .catch((error) => {
            console.error("Error adding cart data after login:", error);
          });
      } else if (action === "addToCart") {
        dispatch(addcarts(cartData))
          .then((res) => {
            setCart(res?.data?.data);
            localStorage.removeItem("reloadFlag");
            localStorage.removeItem("action");
          })
          .catch((error) => {
            console.error("Error adding cart data after login:", error);
          });
      }
    }

    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getwishlists())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch, location, navigate]);
  useEffect(() => {
    dispatch({
      type: "addcartsuccess",
      data: null,
    });
    window.scrollTo(1000, 0);
  }, [dispatch]);
  // const handleScroll = () => {
  //   window.location.reload();
  //   window.scrollTo(0, 0);
  // };
  const productspecs = useSelector(
    ({ productspecifications }) => productspecifications.payload
  );
  const review = useSelector(({ getreviews }) => getreviews.payload);
  const sproduct = useSelector(
    ({ getsingleproducts }) => getsingleproducts.payload
  );
  // const cartmessage = useSelector(({ addcarts }) => addcarts.payload);
  // const you = useSelector(({ foryous }) => foryous.payload);
  // const buy = useSelector(({ buynows }) => buynows.payload);
  //product list
  const proid = id;
  const [pid, setPid] = useState({
    productId: proid,
  });
  //product list
  const [cartid, getCardid] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const [cart, setCart] = useState("");
  const [wish, setWish] = useState("");
  // console.log(cart);
  const handleClick = () => {
    cartid.variantId = sproduct[changeVariant]?.id;
    cartid.units = quantity;
    cartid.variantColor = sproduct[changeVariant]?.variantColor;

    if (Token) {
      dispatch(addcarts(cartid)).then((res) => {
        setCart(res?.data?.data);
      });
    } else {
      // Save cart data to localStorage before navigating
      localStorage.setItem("cartData", JSON.stringify(cartid));
      localStorage.setItem("action", "addToCart");
      navigate("/login", {
        state: { from: window.location.pathname },
      });
    }
  };

  const Clicktobuy = () => {
    setLoad(true);

    cartid.variantId = sproduct[changeVariant]?.id;
    cartid.units = quantity;
    cartid.variantColor = sproduct[changeVariant]?.variantColor;

    if (Token) {
      // First, add the product to the cart
      dispatch(addcarts(cartid))
        .then((res) => {
          setCart(res?.data?.data);
          setLoad(false);
          // Then, redirect to the cart page
          navigate("/cart");
        })
        .catch((error) => {
          setLoad(false);
          console.error("Error:", error);
        });
    } else {
      // Save buy-now data to localStorage before navigating
      localStorage.setItem("cartData", JSON.stringify(cartid));
      localStorage.setItem("action", "buyNow");
      navigate("/login", {
        state: { from: window.location.pathname },
      });
    }
  };
  const fetchData = async () => {
    try {
      await Promise.all([
        dispatch(getsingleproducts(pid)),
        dispatch(productspecifications(pid)),

        dispatch(getreviews(pid)),
      ]);
      setLoad(true);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(globalLoderAction("showLoader"));
      try {
        await Promise.all([
          dispatch(getsingleproducts(pid)),
          dispatch(productspecifications(pid)),
          dispatch(foryous()),
          dispatch(getreviews(pid)),
        ]);
        setLoad(true);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        dispatch(globalLoderAction("hideLoader"));
      }
    };

    fetchData();
  }, [dispatch, pid]);

  useEffect(() => {
    // Tabs List
    const tabss = () => {
      $(".tabs li").click(function () {
        var tabId = $(this).index();
        // Hide all tab content
        $(".tabs_item").hide();
        // Show the clicked tab content
        $(".tabs_item:eq(" + tabId + ")").show();
        // Remove 'current' class from all tabs
        $(".tabs li").removeClass("current");
        // Add 'current' class to the clicked tab
        $(this).addClass("current");
      });
    };

    // Call the tabss function after the component is rendered
    tabss();
    $(".tabs_item:eq(0)").show();
    $(".tabs li:eq(0)").addClass("current");
  }, []);
  const url = `https://aadhimarudham.in/ProductDetails/${id}`;

  const handleCopyLink = () => {
    const messageDiv = document.createElement("div");
    messageDiv.textContent = "Link copied Successfully!";
    Object.assign(messageDiv.style, {
      position: "fixed",
      top: "10%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      borderRadius: "10px",
      textAlign: "center",
      padding: "10px",
      fontSize: "18px",
      color: "white",
      backgroundColor: "black",
      zIndex: "9999",
    });

    document.body.appendChild(messageDiv);

    navigator.clipboard
      .writeText(url)
      .then(() => {
        setTimeout(() => {
          document.body.removeChild(messageDiv);
        }, 1500);
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
        alert("Failed to copy link. Please copy it manually.");
      });
  };
  let variantImages = [];
  if (sproduct) {
    variantImages = JSON.parse(sproduct[changeVariant]?.variantImage).filter(
      (img) => img.length > 2
    );
  }

  const [activeTab, setActiveTab] = useState("benefits");

  // Function to handle tab click
  const handleTabClick = (tabName) => setActiveTab(tabName);
  return (
    <>
      <div
        class="inner-banner"
        style={{ borderTop: "2px solid #e4e4e4" }}
      ></div>

      <div class="product-details-area pt-100 pb-70">
        {Array.isArray(sproduct) && sproduct.length > 0 ? (
          <div class="container">
            <div class="product-details-desc">
              <div class="row ">
                <div className="col-lg-6 col-md-12">
                  <div className="product-details-image mb-30">
                    <img
                      src={process.env.REACT_APP_URL + variantImages[change]}
                      alt="product"
                      style={{ height: "400px", objectFit: "cover" }}
                    />
                  </div>

                  <div className="payment-methods mt-4">
                    <a style={{ display: "flex", width: "20%" }}>
                      {variantImages.map((data, i) => (
                        <img
                          key={i}
                          className="imagehover"
                          onMouseOver={() => setChange(i)}
                          style={{
                            marginRight: "10px",
                            objectFit: "cover",
                            borderColor: change === i ? "#00ff04" : "",
                          }}
                          onClick={() => setChange(i)}
                          src={process.env.REACT_APP_URL + data}
                          alt={`variant-${i}`}
                        />
                      ))}
                    </a>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div
                    class="product-desc mb-30 pl-20"
                    style={{ marginTop: "" }}
                  >
                    <h2 style={{ textTransform: "capitalize" }}>
                      {" "}
                      {sproduct[changeVariant]?.productName}
                    </h2>
                    <div className="mt-3 mb-3">
                      {Array.isArray(sproduct) &&
                        sproduct.map((data, index) => (
                          <button
                            key={index}
                            onClick={() => setChangeVariant(index)}
                            className={`buy-btns  ${
                              changeVariant === index ? "active-variant" : ""
                            }`}
                            style={{
                              marginRight: "10px",
                              textTransform: "capitalize",

                              textAlign: "center",

                              verticalAlign: "middle",
                            }}
                          >
                            {data?.variantName}
                          </button>
                        ))}
                    </div>
                    <div class="product-review">
                      {/* <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star-half"></i>
                      </div> */}
                      {/* <div class="rating-count">(1 customer review)</div> */}
                    </div>
                    <div class="price">
                      <span class="old-price">
                        ₹{sproduct[changeVariant]?.actualPrice}
                      </span>
                      <span class="new-price" style={{ fontWeight: "600" }}>
                        ₹{sproduct[changeVariant]?.discountPrice}
                      </span>
                    </div>
                    {/* <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout
                    </p> */}
                    {/* <ul class="product-category-list">
                      <li>
                        <span>Sku:</span> 6549845321
                      </li>
                      <li>
                        <span>Category:</span> Makeup
                      </li>
                      <li>
                        <span>Tags:</span> Airbrush, Matte, Skin
                      </li>
                    </ul> */}
                    {/* <div className="input-counter">
                      <span
                        className={`minus-btn ${
                          quantity <= 1 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (quantity > 1) {
                            setQuantity(quantity - 1);
                          }
                        }}
                      >
                        <i className="bx bx-minus"></i>
                      </span>

                      <input
                        type="number"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        className="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i className="bx bx-plus"></i>
                      </span>
                    </div> */}
                    <div class="product-share">
                      <ul>
                        <li>
                          <Link
                            className="buy-btns"
                            style={{ textDecoration: "none" }}
                            onClick={handleCopyLink}
                          >
                            <BsLink45Deg
                              className="btns-link"
                              style={{ color: "#000", fontSize: "24px" }}
                            />
                            &nbsp; Copy Link
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div class="product-share mt-4">
                      <h6>About this Product:</h6>
                      <p style={{ lineHeight: "30px" }}>
                        {" "}
                        {productspecs?.productDescription
                          ? productspecs?.productDescription
                          : false}
                      </p>
                    </div>{" "}
                    <div class="product-add-btn">
                      <button
                        type="submit"
                        className="default-btn two border-radius-5 mb-2"
                        onClick={() => {
                          setLoad(true);

                          // Check if token exists
                          if (Token) {
                            // If token exists, proceed with adding to wishlist
                            dispatch(
                              addwishlists({
                                productId: sproduct[changeVariant].id,
                              })
                            ).then((res) => {
                              setWish(res.data.data);
                              setLoad(false);
                            });
                          } else {
                            // If no token, navigate to login page and pass current location
                            navigate("/login", {
                              state: { from: window.location.pathname },
                            });
                            setLoad(false); // Set loading to false in case of redirection
                          }
                        }}
                      >
                        Add to Wishlist
                      </button>
                      <button
                        type="submit"
                        class="default-btn two border-radius-5"
                        onClick={handleClick}
                      >
                        Add To Cart
                      </button>
                      <button
                        type="submit"
                        className="default-btn two border-radius-5"
                        onClick={Clicktobuy}
                      >
                        Buy Now
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      {wish ? (
                        <p
                          style={{
                            padding: "10px",
                            backgroundColor: "#e4ffe5",
                            marginTop: "15px",
                            width: "200px",
                          }}
                        >
                          {wish}
                        </p>
                      ) : (
                        false
                      )}{" "}
                      {cart ? (
                        <p
                          style={{
                            padding: "10px",
                            backgroundColor: "#e4ffe5",
                            marginTop: "15px",
                            width: "200px",
                          }}
                        >
                          {cart}
                        </p>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          false
        )}
      </div>

      <div className="shop-details-tab-area pb-70">
        <div className="container">
          <div className="tab shop-detls-tab">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <ul className="tabs">
                  <li
                    onClick={() => handleTabClick("benefits")}
                    className={activeTab === "benefits" ? "active" : ""}
                  >
                    Benefits
                  </li>
                  <li
                    onClick={() => handleTabClick("additionalInfo")}
                    className={activeTab === "additionalInfo" ? "active" : ""}
                  >
                    Additional Information
                  </li>
                  <li
                    onClick={() => handleTabClick("reviews")}
                    className={activeTab === "reviews" ? "active" : ""}
                  >
                    Reviews <span> </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              {/* Benefits Tab Content */}
              {activeTab === "benefits" && (
                <div className="tab_content active">
                  <div className="shop-detls-tab-content">
                    {productspecs?.productBenefits ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            productspecs?.productBenefits
                          ),
                        }}
                      ></p>
                    ) : (
                      <p>
                        <center>
                          <b style={{ color: "black" }}>No Benefits Found</b>
                        </center>
                      </p>
                    )}
                  </div>
                </div>
              )}

              {/* Additional Information Tab Content */}
              {activeTab === "additionalInfo" && (
                <div className="tab_content active">
                  <div className="shop-detls-tab-content">
                    {productspecs?.moreInfo ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(productspecs?.moreInfo),
                        }}
                      ></p>
                    ) : (
                      <p>
                        <center>
                          <b style={{ color: "black" }}>No Information Found</b>
                        </center>
                      </p>
                    )}
                  </div>
                </div>
              )}

              {/* Reviews Tab Content */}
              {activeTab === "reviews" && (
                <div className="tab_content active">
                  <div className="shop-detls-tab-content">
                    <div className="shop-review-form">
                      {Array.isArray(review) ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h1
                              style={{
                                fontSize: "150px",
                                fontFamily: "Source Sans Pro",
                              }}
                            >
                              {review?.reviewData?.overallRatings}
                            </h1>
                          </div>
                          <div className="" style={{ width: "500px" }}>
                            <div class="">
                              <div className="mt-3 mb-3">
                                <div>
                                  <span> Excellent </span>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{
                                        backgroundColor: "green",
                                        borderRadius: "30px",
                                        width:
                                          review?.reviewData?.excellent + "%",
                                      }}
                                      aria-valuenow="29"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>
                                <div>
                                  <span> Best </span>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{
                                        backgroundColor: "lightgreen",
                                        borderRadius: "30px",
                                        width: review?.reviewData?.best + "%",
                                      }}
                                      aria-valuenow="29"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>

                                <div>
                                  <span> Good </span>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{
                                        backgroundColor: "yellow",
                                        borderRadius: "30px",
                                        width: review?.reviewData?.good + "%",
                                      }}
                                      aria-valuenow="29"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>
                                <div>
                                  <span> Below Average </span>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{
                                        backgroundColor: "orange",
                                        borderRadius: "30px",
                                        width: review?.reviewData?.poor + "%",
                                      }}
                                      aria-valuenow="29"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>
                                <div>
                                  <span> Poor </span>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{
                                        backgroundColor: "red",
                                        borderRadius: "30px",
                                        width:
                                          review?.reviewData?.verypoor + "%",
                                      }}
                                      aria-valuenow="29"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        false
                      )}
                      {/* test */}
                      {Array.isArray(review) ? (
                        <p style={{ textAlign: "center" }}>
                          Based on {review?.reviewData?.noOfRatings}
                          reviews
                        </p>
                      ) : (
                        false
                      )}
                      {Array.isArray(review?.reviews) &&
                      review?.reviews.length !== 0 ? (
                        review?.reviews.map((R) => (
                          <div class="product-review-form">
                            <div class="review-comments">
                              <div
                                class="review-item"
                                style={{ borderTop: "none" }}
                              >
                                <div style={{ display: "flex" }}>
                                  {R?.customerImage ? (
                                    <img
                                      src={`${process.env.REACT_APP_URL}${R.customerImage}`}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        objectFit: "cover",
                                        marginRight: "10px",
                                        borderRadius: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={user}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        objectFit: "cover",
                                        marginRight: "10px",
                                        borderRadius: "30px",
                                      }}
                                    />
                                  )}
                                  <h5
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "5px",
                                      color: "#000",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {R?.customerName}
                                  </h5>
                                </div>
                                <span
                                  class="rating  mt-1"
                                  style={{ display: "flex" }}
                                >
                                  {Array.from({ length: 5 }, (data, i) =>
                                    i < R?.rating ? (
                                      <span style={{ margin: "2px" }}>
                                        <FaStar
                                          style={{
                                            color: "orange",
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span style={{ margin: "1px" }}>
                                        <AiOutlineStar />
                                      </span>
                                    )
                                  )}
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      marginTop: "5px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    {R?.rating} out of 5{" "}
                                  </span>
                                </span>
                                <span>
                                  <strong> </strong>
                                </span>
                                <p
                                  style={{
                                    marginTop: "5px",
                                  }}
                                >
                                  {R?.review}
                                </p>
                                <a class="review-report-link">
                                  <strong>{R.createdAt}</strong>
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <center>
                          <h4>{review}</h4>
                        </center>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {
        // <div class="related-area pb-70">
        //   <div class="container">
        //     <div class="section-title text-center mb-45">
        //       <h2>Related Products</h2>
        //     </div>
        //     <div class="row justify-content-center">
        //       {Array.isArray(you) && you.length !== 0
        //         ? you
        //             .filter((y) => y?.discountPrice >= 1)
        //             ?.map((y) => (
        //               <div class="col-lg-3 col-md-6">
        //                 <div class="best-seller-item">
        //                   <div class="best-seller-img">
        //                     <Link
        //                       to={"/ProductDetails/" + y.id}
        //                       style={{ textTransform: "capitalize" }}
        //                       onClick={() => fetchData()}
        //                     >
        //                       <img
        //                         src={
        //                           process.env.REACT_APP_URL + y?.productImage
        //                         }
        //                         alt={y?.productName}
        //                         style={{ objectFit: "cover" }}
        //                       />
        //                     </Link>
        //                     <ul class="best-seller-action">
        //                       <li>
        //                         {" "}
        //                         <a
        //                           onClick={() => {
        //                             setLoad(true);
        //                             dispatch(
        //                               addwishlists({ productId: y.id })
        //                             ).then((res) => {
        //                               message.success({
        //                                 content: res.data.data,
        //                                 style: {
        //                                   marginTop: "10vh",
        //                                 },
        //                               });
        //                               setLoad(false);
        //                             });
        //                           }}
        //                         >
        //                           {Array.isArray(wishlist?.data) &&
        //                           wishlist?.data?.length !== 0 ? (
        //                             wishlist?.data?.some(
        //                               (item) => item.id === y.id
        //                             ) ? (
        //                               <>
        //                                 <BsFillSuitHeartFill
        //                                   style={{
        //                                     fontSize: "14px",
        //                                     marginTop: "8px",
        //                                   }}
        //                                 />
        //                               </>
        //                             ) : (
        //                               <i className="flaticon-like"></i>
        //                             )
        //                           ) : (
        //                             <BsFillSuitHeartFill
        //                               style={{
        //                                 fontSize: "14px",
        //                                 marginTop: "8px",
        //                               }}
        //                             />
        //                           )}
        //                         </a>
        //                       </li>
        //                       {/* <li>
        //                       <a
        //                         href="#"
        //                         data-bs-toggle="modal"
        //                         data-bs-target="#productsQuickView"
        //                       >
        //                         <i class="flaticon-visibility"></i>
        //                       </a>
        //                     </li> */}
        //                     </ul>
        //                   </div>
        //                   <div class="content">
        //                     <div class="top-title">
        //                       <h3>
        //                         <Link
        //                           to={"/ProductDetails/" + y.id}
        //                           style={{ textTransform: "capitalize" }}
        //                         >
        //                           {" "}
        //                           <h6
        //                             style={{
        //                               textTransform: "capitalize",
        //                               fontSize: "12px",
        //                               color: "#000",
        //                             }}
        //                           >
        //                             {y?.productName}
        //                           </h6>
        //                         </Link>
        //                       </h3>
        //                       <Link
        //                         to={"/ProductDetails/" + y.id}
        //                         style={{ textTransform: "capitalize" }}
        //                         class="buy-btn"
        //                         onClick={Clicktobuy}
        //                       >
        //                         Buy Now
        //                       </Link>
        //                     </div>
        //                     <span>₹{y?.discountPrice}</span>
        //                   </div>
        //                 </div>
        //               </div>
        //             ))
        //         : false}
        //     </div>
        //   </div>
        // </div>
      }

      <div
        class="modal fade productsQuickView"
        id="productsQuickView"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close-on"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i class="bx bx-x"></i>
              </span>
            </button>
            <div class="product-details-desc">
              {Array.isArray(sproduct) && sproduct.length > 0 ? (
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12">
                    <div class="products-quickView-image">
                      <a
                        href={
                          JSON.parse(sproduct[changeVariant]?.variantImage)[
                            change
                          ]
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_URL +
                            JSON.parse(sproduct[changeVariant]?.variantImage)[
                              change
                            ]
                          }
                          alt=""
                        />
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12">
                    <div class="product-desc">
                      <h3 style={{ textTransform: "capitalize" }}>
                        {" "}
                        {sproduct[changeVariant].productName}
                      </h3>
                      <div class="product-review">
                        {Array.isArray(sproduct)
                          ? sproduct?.map((data, index) => (
                              <button
                                onClick={() => setChangeVariant(index)}
                                className="btn btn-light "
                                style={{
                                  marginRight: "10px",
                                  textTransform: "capitalize",
                                  borderRadius: "0px",
                                  textAlign: "center",
                                  padding: "0",
                                  verticalAlign: "middle",
                                }}
                              >
                                {data?.variantName}
                              </button>
                            ))
                          : false}
                      </div>

                      <div class="price">
                        <span class="new-price">
                          {" "}
                          ₹{sproduct[changeVariant]?.discountPrice[change]}{" "}
                        </span>{" "}
                        <span class="old-price">
                          {" "}
                          ₹{sproduct[changeVariant]?.actualPrice[change]}
                        </span>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout
                      </p>

                      <div class="product-add-btn">
                        <button
                          type="submit"
                          class="default-btn two border-radius-5"
                        >
                          Add To Cart
                        </button>
                      </div>
                      <div class="product-share">
                        <ul>
                          <li>
                            <span>Share:</span>
                          </li>
                          <li>
                            <a
                              href="https://www.facebook.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i class="bx bxl-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i class="bx bxl-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i class="bx bxl-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.google.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i class="bx bxl-google"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Productdetail;
