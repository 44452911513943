import React, { useEffect, useState } from "react";
import "./OrderDetail.css";
import { useParams } from "react-router-dom";
import { getordersdetails, globalLoderAction } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
function TrackingOrder() {
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getordersdetails(getid))])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  const getdetails = useSelector(
    ({ getordersdetails }) => getordersdetails.payload
  );
  return (
    <div>
      {" "}
      <div class="container-fluid ">
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Tracking Order
          </h3>
          <div>
            <div class="row display-flex justify-content-center mt-5 mb-5">
              <div
                class={
                  getdetails?.orderInfo?.orderStatus === "accepted" ||
                  getdetails?.orderInfo?.orderStatus === "packaging" ||
                  getdetails?.orderInfo?.orderStatus === "dispatched" ||
                  getdetails?.orderInfo?.orderStatus === "delivered"
                    ? "order-tracking completed"
                    : "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Accepted
                  <br />
                  <span>{getdetails?.orderInfo?.createdAt}</span>
                </p>
              </div>
              <div
                class={
                  getdetails?.orderInfo?.orderStatus === "packaging" ||
                  getdetails?.orderInfo?.orderStatus === "dispatched" ||
                  getdetails?.orderInfo?.orderStatus === "delivered"
                    ? "order-tracking completed"
                    : "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Packaging
                  <br />
                </p>
              </div>
              <div
                class={
                  getdetails?.orderInfo?.orderStatus === "dispatched" ||
                  getdetails?.orderInfo?.orderStatus === "delivered"
                    ? "order-tracking completed"
                    : "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Dispatched
                  <br />
                </p>
              </div>
              <div
                class={
                  getdetails?.orderInfo?.orderStatus === "delivered"
                    ? "order-tracking completed"
                    : "order-tracking"
                }
              >
                <span class="is-complete"></span>
                <p>
                  Delivered
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div>
              {" "}
              <h2 class="pb-2" style={{ textDecoration: "underline" }}>
                Tracking Information
              </h2>
              <h6 style={{ fontSize: "20px", textTransform: "capitalize" }}>
                {" "}
                Tracking ID :{" "}
                {getdetails?.orderInfo?.trackingInfo &&
                  getdetails?.orderInfo?.trackingInfo.match(/[^,]*/)[0].trim()}
              </h6>
              <h6 style={{ fontSize: "20px", textTransform: "capitalize" }}>
                {" "}
                Courier Name :{" "}
                {getdetails?.orderInfo?.trackingInfo &&
                  getdetails?.orderInfo?.trackingInfo
                    .match(/,.*/)[0]
                    .slice(1)
                    .trim()}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingOrder;
