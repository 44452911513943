import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getcarts,
  removecarts,
  updatecarts,
  globalLoderAction,
} from "../Redux/Action";
import "./cart.css";

function Cart() {
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState([]);

  const getCart = useSelector(({ getcarts }) => getcarts.payload);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getcarts())])
      .then(() => setLoad(false))
      .catch(() => console.log("Waiting For Network"))
      .finally(() => dispatch(globalLoderAction("hideLoader")));
  }, [dispatch]);

  useEffect(() => {
    if (getCart?.cart) {
      const quantities = getCart.cart.map((item) => item.units);
      setQuantity(quantities);
    }
  }, [getCart]);

  const handleClickplus = (i, C) => {
    dispatch(updatecarts({ units: "add", cartId: C.id }));
    const updatedQuantities = [...quantity];
    updatedQuantities[i] = updatedQuantities[i] + 1;
    setQuantity(updatedQuantities);
  };

  const handleClickminus = (i, C) => {
    if (quantity[i] > 0) {
      dispatch(updatecarts({ units: "sub", cartId: C.id }));
      const updatedQuantities = [...quantity];
      updatedQuantities[i] = updatedQuantities[i] - 1;
      setQuantity(updatedQuantities);
    }
  };

  return (
    <>
      <div className="inner-banner inner-banner-bg10">
        <div className="container">
          <div className="inner-title">
            <h3 style={{ color: "#05af6a" }}>Cart</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Cart</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="cart-wraps-area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form>
                <div className="cart-wraps">
                  <div className="cart-table table-responsive">
                    {Array.isArray(getCart?.cart) &&
                    getCart?.cart.length > 0 ? (
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getCart?.cart.map((C, i) => (
                            <tr key={C.id}>
                              <td
                                className="product-thumbnail"
                                data-label="Product"
                              >
                                <Link to={`/ProductDetails/${C.productId}`}>
                                  <img
                                    src={`${process.env.REACT_APP_URL}${C.variantImage}`}
                                    alt="item"
                                    className="proo"
                                  />
                                </Link>
                              </td>
                              <td className="product-name" data-label="Name">
                                <Link
                                  to={`/ProductDetails/${C.productId}`}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "black",
                                  }}
                                >
                                  {C.productName}
                                </Link>
                              </td>
                              <td
                                className="product-price"
                                data-label="Unit Price"
                              >
                                <span className="unit-amount">
                                  ₹ {C.singleProductPrice}
                                </span>
                              </td>
                              <td
                                className="product-quantity"
                                data-label="Quantity"
                              >
                                <div className="input-counter">
                                  <span
                                    className="minus-btn"
                                    onClick={() => handleClickminus(i, C)}
                                  >
                                    <i className="bx bx-minus"></i>
                                  </span>
                                  <input
                                    type="text"
                                    max="1"
                                    value={C.units}
                                    onChange={(e) =>
                                      setQuantity(e.target.value)
                                    }
                                  />
                                  <span
                                    className="plus-btn"
                                    onClick={() => handleClickplus(i, C)}
                                  >
                                    <i className="bx bx-plus"></i>
                                  </span>
                                </div>
                              </td>
                              <td
                                className="product-subtotal"
                                data-label="Total"
                              >
                                <span className="subtotal-amount">
                                  ₹{C.totalPrice}
                                </span>
                              </td>
                              <td
                                className="product-action"
                                data-label="Action"
                              >
                                <a
                                  onClick={() => {
                                    setLoad(true);
                                    dispatch(
                                      removecarts({ cartId: C.id })
                                    ).then(() => setLoad(false));
                                  }}
                                  className="remove"
                                 >
                                  <i
                                    style={{
                                      fontSize: "23px",
                                      color: "#6b9246",
                                    }}
                                    className="bx bx-trash"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <center>
                        <h3 className="mb-5 pb-3">No Cart Product Found</h3>
                        <a href="/shop" className="default-btn border-radius-5">
                          Shop Now
                        </a>
                      </center>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {Array.isArray(getCart?.cart) && getCart?.cart.length > 0 && (
          <Link to="/checkout">
            <button
              type="button"
              className="default-btn border-radius-5 mt-5 mb-5"
            >
              Proceed to Checkout
            </button>
          </Link>
        )}
      </div>
    </>
  );
}

export default Cart;
